@font-face {
  font-family: caslon;
  src: url(../../assets/font/acaslonpro-italic.otf);
}

@font-face {
  font-family: italiano;
  src: url(../../assets/font/PlayfairDisplay-SemiBoldItalic.ttf);
}
@font-face {
  font-family: montserrat;
  src: url(../../assets/font/Montserrat-SemiBold.ttf);
}

@font-face {
  font-family: futura;
  src: url(../../assets/font/FuturaPTBook.otf);
}

.AboutUs {
  padding-top: 150px;
}
.about {
  font-weight: bolder;
  line-height: 120%;
  text-align: justify;
  padding-left: 40px;
  padding-right: 40px;
}

#normal {
  font-style: normal;
}

#caslon {
  font-family: monserrat;
  color: #000;
  background: #fff;
  mix-blend-mode: multiply;
  position: relative;
}

#caslon:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #0c2c44, #d4cc14);
  mix-blend-mode: screen;
  position: absolute;
  top: 0;
  left: 0;
}

#italik {
  font-family: italiano;
}

.description {
  text-align: justify;
  padding-left: 40px;
  padding-right: 40px;
}
.line_description {
  line-height: 170%;
}

#description1 {
  font-size: 15pt;
}
#description2 {
  font-size: 10pt;
}

@media (max-width: 575.98px) {
  .AboutUs {
    padding-top: 30px;
  }
  #inverlat2 {
    padding-top: 20px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .AboutUs {
    padding-top: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .AboutUs {
    padding-top: 50px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .AboutUs {
    padding-top: 50px;
  }
}

@media (min-width: 1200px) {
  .AboutUs {
    padding-top: 100px;
  }
}
