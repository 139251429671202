/* Estilos para Slider */
.Home {
  z-index: 2;
}

.carousel-indicators button {
  border-radius: 100% !important;
  width: 20px !important;
  height: 20px !important;
}

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(1, 1);
  }
  to {
    -webkit-transform: scale(1.09, 1.09);
  }
}

@keyframes zoom {
  from {
    transform: scale(1, 1);
  }
  to {
    transform: scale(1.2, 1.2);
  }
}

.carousel-inner > .carousel-item > img {
  -webkit-animation: zoom 7s;
  animation: zoom 7s;
}

@media (max-width: 575.98px) {
  .carousel-inner > .carousel-item > img {
    height: 85vh;
    width: 100%;
    object-fit: cover;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 1200px) {
}
