/*Estilos para superponer texto sobre la imagen*/
.project {
  position: relative;
}
.position-project {
  position: absolute;
  width: 100%;
  text-align: center;
}
.fondo-project {
  object-fit: cover;
}

.nav-link {
  color: white !important;
}

.nav-link.active {
  color: black !important;
  font-weight: bold;
}

/*estilos para los projectos*/
.title-project {
  color: white;
}
.obra {
  position: relative;
}

.image {
  display: block;
  object-fit: cover;
}

.overlay {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
  opacity: 0;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
  font-weight: 600;
}

.obra:hover .overlay {
  opacity: 1;
}

.title-overlay {
  font-size: 16px;
}

.category-overlay {
  font-size: 12px;
  float: left !important;
  color: black;
}

.galery-overlay {
  font-size: 12px;
  bottom: 0% !important;
  position: absolute;
  right: 0;
  color: black;
  text-decoration: none;
}

hr {
  border: 0;
  height: 3px !important;
  background: white;
  background: linear-gradient(to right, yellow, white);
}

@media (max-width: 575.98px) {
  .fondo-project {
    width: 100%;
    height: 1550px;
  }
  .image {
    width: 100%;
    height: 200px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .fondo-project {
    width: 100%;
    height: 850px;
  }
  .image {
    width: 100%;
    height: 200px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .fondo-project {
    width: 100%;
    height: 650px;
  }
  .image {
    width: 100%;
    height: 200px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .fondo-project {
    width: 100%;
    height: 650px;
  }
  .image {
    width: 100%;
    height: 200px;
  }
}

@media (min-width: 1200px) {
  .fondo-project {
    width: 100%;
    height: 650px;
  }

  .image {
    width: 100%;
    height: 200px;
  }
}
