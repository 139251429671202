.principal {
  position: relative;
  padding-left: 0% !important;
  padding-right: 0% !important;
}

.imagen {
  position: absolute;
  object-fit: cover;
}
.design {
  width: 75%;
}

.contain-text {
  position: absolute;
  margin-top: 6%;
  right: 15%;
  width: 600px;
}
.text {
  font-size: 36pt;
  font-weight: bolder;
  line-height: 120%;
  font-family: Montserrat, sans-serif;
  color: #fec500;
}
.sub-text {
  font-size: 15pt;
  text-align: center;
  list-style: none;
}

li > svg {
  color: #fec500;
}

@media (max-width: 575.98px) {
  .principal {
    height: 200px;
  }
  .design {
    width: 100%;
    height: 200px;
  }
  .contain-text {
    margin-top: 5%;
    width: 250px;
  }
  .text {
    font-size: 12pt;
    font-weight: bolder;
    line-height: 120%;
    font-family: Montserrat, sans-serif;
    color: #fec500;
  }
  .sub-text {
    font-size: 10pt;
    text-align: center;
    list-style: none;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .principal {
    height: 400px;
  }
  .design {
    width: 100%;
    height: 400px;
  }
  .contain-text {
    margin-top: 5%;
    width: 400px;
  }
  .text {
    font-size: 26pt;
    font-weight: bolder;
    line-height: 120%;
    font-family: Montserrat, sans-serif;
    color: #fec500;
  }
  .sub-text {
    font-size: 15pt;
    text-align: center;
    list-style: none;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .principal {
    height: 450px;
  }
  .design {
    width: 100%;
    height: 450px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .principal {
    height: 600px;
  }
  .design {
    width: 100%;
    height: 600px;
  }
}

@media (min-width: 1200px) {
  .principal {
    height: 600px;
  }
}
