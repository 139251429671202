.contact {
  font-family: futura;
  color: #656565;
}

#negrita {
  font-weight: 700;
}

#tel {
  text-decoration: none;
  color: #656565;
}
.mapa {
  width: auto;
  height: 300px;
}

.logo-contact {
  width: 200px;
  height: auto;
}

@media (max-width: 575.98px) {
}

@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 1200px) {
}
