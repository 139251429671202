@font-face {
  font-family: Shojumaru;
  src: url(../../assets/font/Shojumaru-Regular.ttf);
}
.titleNamek,
.subtitleNamek {
  font-family: Shojumaru;
}

.header {
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 120px;
  background-color: transparent;
  color: white;
  transform: translateY(0);
  transition: transform 0.6s ease;
}

.header2 {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 130px;
  background-color: white;
  transform: translateY(10);
  transition: transform 6s ease;
  animation: myanimation 2s;
  z-index: 3;
}
@keyframes myanimation {
  0% {
    background-color: transparent;
    /*color: transparent;*/
  }
  35% {
    background-color: gray;
    /*color: rosybrown;*/
  }
  100% {
    background-color: white;
    /*color: #d4cc14;*/
  }
}
/*-webkit-text-fill-color: transparent;
  background: linear-gradient(to left, #d4cc14, #0c2c44 100%);
  -webkit-background-clip: text !important;*/

.header.shadow {
  box-shadow: 0 9px 9px -9px rgba(0, 0, 0, 0.13);
}

.header.hidden {
  transform: translateY(-110%);
}

#logo {
  max-width: 150px;
  float: right;
}

@media (max-width: 575.98px) {
  .header,
  .header2 {
    height: 50px;
  }
  /*---ESTILOS PARA LOGO Y TITULO NAMEK*/
  #logo {
    max-width: 50px;
  }
  .titleNamek {
    font-size: 10pt;
  }
  .subtitleNamek {
    font-size: 6pt;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .header,
  .header2 {
    height: 60px;
  }
  /*---ESTILOS PARA LOGO Y TITULO NAMEK*/
  #logo {
    max-width: 80px;
  }
  .titleNamek {
    font-size: 15pt;
  }
  .subtitleNamek {
    font-size: 8pt;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .header,
  .header2 {
    height: 65px;
  }
  /*---ESTILOS PARA LOGO Y TITULO NAMEK*/
  #logo {
    max-width: 80px;
  }
  .titleNamek {
    font-size: 15pt;
  }
  .subtitleNamek {
    font-size: 8pt;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .header,
  .header2 {
    height: 80px;
  }
  /*---ESTILOS PARA LOGO Y TITULO NAMEK*/
  #logo {
    max-width: 100px;
  }
  .titleNamek {
    font-size: 20pt;
  }
  .subtitleNamek {
    font-size: 10pt;
  }
}

@media (min-width: 1200px) {
  /*---ESTILOS PARA LOGO Y TITULO NAMEK*/
  .titleNamek {
    font-size: 26pt;
  }
  .subtitleNamek {
    font-size: 13pt;
  }
}
